
<template>
  <div class="studentLeaveAudit">
    <div class="flex-box">
      <el-button type="primary">导 出</el-button>
      <div class="flex-box">
        <el-select v-model="queryStatus"
                   placeholder="请选择状态">
          <el-option v-for="status in statusList"
                     :key="status.value"
                     :label="status.name"
                     :value="status.value"></el-option>
        </el-select>
        <el-date-picker v-model="queryTime"
                        type="daterange"
                        class="picker"
                        range-separator="至"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <el-input class="input-box"
                  placeholder="请输入请假人"
                  v-model="queryApplicant"></el-input>
        <el-button type="primary"
                   @click="loadStudentLeaves(1)">查 询</el-button>
      </div>
    </div>
    <el-table :data="leaveTable">
      <el-table-column label="申请人姓名"
                       prop="nickname"
                       align="center"></el-table-column>
      <el-table-column label="请假类型"
                       prop="typeStr"
                       align="center"></el-table-column>
      <el-table-column label="请假原因"
                       prop="content"
                       align="center"></el-table-column>
      <el-table-column label="开始时间"
                       align="center">
        <template slot-scope="scope">{{scope.row.start_time}} {{scope.row.start_hour}}:{{scope.row.start_min}}</template>
      </el-table-column>
      <el-table-column label="结束时间"
                       align="center">
        <template slot-scope="scope">{{scope.row.end_time}} {{scope.row.end_hour}}:{{scope.row.end_min}}</template>
      </el-table-column>
      <el-table-column label="时长"
                       prop="duration"
                       align="center"></el-table-column>
      <el-table-column label="附加"
                       align="center">
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px"
                    v-if="scope.row.url"
                    :src="scope.row.photoUrl"
                    :preview-src-list="scope.row.photoUrlList">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="状态"
                       prop="statusStr"
                       align="center"> </el-table-column>
      <el-table-column label="审核意见"
                       prop="opinion"
                       align="center"></el-table-column>
      <el-table-column label="操作"
                       align="center"
                       width="120px">
        <template slot-scope="scope">
          <el-button type="warning"
                     :disabled="scope.row.status!==0"
                     @click="toExamineDetail(scope.row.id)">审 核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadStudentLeaves"></el-pagination>
    <el-dialog :visible.sync="auditVisible"
               title="审核学生请假"
               width="600px">
      <el-form>
        <el-form-item label="意见">
          <el-input placeholder="请输入意见"
                    type="textarea"
                    :rows="2"
                    v-model="option"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer">
        <el-button type="success"
                   @click="auditClick(1)">同 意</el-button>
        <el-button type="primary"
                   @click="auditClick(2)">拒 绝</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "studentLeaveAudit",
  data() {
    return {
      page: { pageCount: 1, currentPage: 0 },
      leaveTable: [],
      queryStatus: "",
      queryTime: "",
      queryApplicant: "",
      auditVisible: false,
      option: "",
      currentId: -1,
      statusList: [
        { name: "待审批", value: 0 },
        { name: "通过", value: 1 },
        { name: "拒绝", value: 2 },
        { name: "已撤销", value: 3 },
      ],
    };
  },
  created() {
    this.loadStudentLeaves(1);
  },
  methods: {
    loadStudentLeaves(page) {
      const that = this;
      let data = { indexNo: page };
      if (this.queryStatus) {
        data.status = this.queryStatus;
      }
      if (this.queryTime) {
        data.start_time = this.queryTime[0];
        data.end_time = this.queryTime[1];
      }
      if (this.queryApplicant) {
        data.name = this.queryApplicant;
      }
      this.$post("/common/queryStuLeaveByTea.do", data)
        .then((res) => {
          that.page.currentPage = page;
          that.page.pageCount = res.data.pageCount;
          let length = res.data.rows.length;
          for (let i = 0; i < length; i++) {
            let item = res.data.rows[i];
            if (item.status === 0) {
              item.statusStr = "待审批";
            } else if (item.status === 1) {
              item.statusStr = "通过";
            } else if (item.status === 2) {
              item.statusStr = "拒绝";
            } else {
              item.statusStr = "已撤销";
            }
            if (item.type === 1) {
              item.typeStr = "事假";
            } else if (item.type === 2) {
              item.typeStr = "病假";
            } else {
              item.typeStr = "其他";
            }
            item.photoUrl = that.$fileUploadDomain + item.url;
            item.photoUrlList = [];
            item.photoUrlList.push(that.$fileUploadDomain + item.url);
          }
          that.leaveTable = res.data.rows;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    toExamineDetail(id) {
      this.auditVisible = true;
      this.currentId = id;
    },
    auditClick(status) {
      const that = this;
      this.$post("/common/updateStuLeaveStatus.do", {
        id: this.currentId,
        status,
        opinion: this.option,
      })
        .then((res) => {
          that.auditVisible = false;
          that.$message({
            type: "success",
            message: res.message,
          });
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    selectStatusChange() {},
  },
};
</script>

<style scoped lang="scss">
.input-box {
  width: 217px;
  margin: 0 5px;
}
.flex-box {
  display: flex;
  justify-content: space-between;
}
.picker {
  margin-left: 5px;
}
</style>
